/* eslint-disable max-lines */

import { EnvName } from 'common/app-constants';

export interface Mocks {
    connectedPortal: {
        graphql: {
            url: string;
            queries: {
                // [key: string]: boolean | undefined;
                MOCK_ALL?: boolean;
                deleteAlertsByIds?: boolean;
                queryAlertsByDealerId?: boolean;
                queryAlertsBySerialNo?: boolean;
                queryAlertsBySystemId?: boolean;
                queryDealerById?: boolean;
                queryDealers?: boolean;
                queryDealersByDistributorId?: boolean;
                queryDealersById?: boolean;
                queryDealerStats?: boolean;
                queryDisabledDataSharingByDealerId?: boolean;
                queryDistributorById?: boolean;
                queryDistributorStats?: boolean;
                queryIduConfiguration?: boolean;
                queryIduHistoricalDataBySerialNo?: boolean;
                queryIduStatusBySerialNo?: boolean;
                queryNotificationsBySerialNo?: boolean;
                queryNotificationPreferencesBySystemId?: boolean;
                queryOduConfiguration?: boolean;
                queryOduHistoricalDataBySerialNo?: boolean;
                queryOduStatusResponseBySerialNo?: boolean;
                queryProductBySerialNo?: boolean;
                queryPropertiesByDealerId?: boolean;
                queryPropertyById?: boolean;
                queryQueuedCustomersByDealerId?: boolean;
                querySystemsByPropertyId?: boolean;
                queryWallControlBySerialNo?: boolean;
                queryWallControlConfigBySerialNo?: boolean;
                queryWallControlHumidAndVentBySerialNo?: boolean;
                queryWallControlNotificationsBySerialNo?: boolean;
                queryWallControlStatusBySerialNo?: boolean;
                queryWallControlSystemInfoBySerialNo?: boolean;
                queryWallControlZoningBySerialNo?: boolean;
                updateDealerById?: boolean;
                updateIduConfigBySerialNo?: boolean;
                updateOduConfigBySerialNo?: boolean;
                updateInfinityWallControlConfigBySerialNo?: boolean;
                updateEcobeeWallControlConfigBySerialNo?: boolean;
                queryConnectedControlsByDistributorId?: boolean;
                queryDealerLoginsByDistributorId?: boolean;
                queryDealerOpportunityReportUsageByDistributorId?: boolean;
                queryDealerControlStatus?: boolean;
                addDealerControl?: boolean;
                queryDiagnosticTestEquipmentBySerialNo?: boolean;
                runDiagnosticsTestBySerialNo?: boolean;
                queryRuntimeReportBySerialNo?: boolean;
                queryDistributors?: boolean;
                publishCommand?: boolean;
                trackActivity?: boolean;
                activateMQTTControlBySerialNo?: boolean;
                activateEcobeeControlBySerialNo?: boolean;
                requestEcobeePinByDealerId?: boolean;
                queryUnregisteredControlsByDealerId?: boolean;
                queryFactoryStats?: boolean;
                queryCustomerLoginsForFactory?: boolean;
                queryConnectedControlsForFactory?: boolean;
                queryLinkedDealersByDealerId?: boolean;
                removeQueuedControlBySerialNo?: boolean;
                removeConnectedControlBySerialNo?: boolean;
                querySystemDataEventsBySerialNo?: boolean;
                queryCustomerByDistributorId?: boolean;
                queryCustomerByFactory?: boolean;
                querySystemBySerialNo?: boolean;
                queryFadReportForFactory?: boolean;
                queryFaultReportForFactory?: boolean;
                querySystemFaultsBySystemId?: boolean;
                updateProductInfoBySystemId?: boolean;
                updateCarrierELTWallControlConfigBySerialNo?: boolean;
                queryCarrierELTComfortProfile?: boolean;
                queryELTRuntimeReportBySerialNo?:boolean;
                queryDealershipActivityReportByDealerId?: boolean;
                queryScheduledVppEventsBySerialNo?: boolean;
                queryFadProgressionByDealerID?: boolean;
                queryVppEligibilityByDealerID?: boolean;
                recoverPassword?: boolean;
            }
        },
        rest: {
            url: string;
            apis: {
                'ui/config'?: boolean;
            }
        }
    }
}

export interface PrivateConfig {
    envName: EnvName;
    production: boolean;
    brand: string;
    appId: string;
    googleMapAPI: string,
    auth: {
        baseUrl: string;
        clientId: string;
        identityProviders?: {
            type: string;
            id: string;
        }[];
    };
    addressDoctor: {
        login: string;
        password: string;
    };
    baseUrl: string;
    chatbotUrl: string;
    connectedPortal: {
        url: string;
        key: string;
        webSocketUrl: string;
        webSocketKey: string;
        homeOwnerResetAPI: string;
    };
    coolCalcAPIDomain: string;
    coolCalcEnv: string;
    documents?: {
        whatsNewAge: number;
        bulletinAge: number
    };
    envPath: string;
    features: {
        accountAdmin: boolean;
        accountAdminDealers: boolean;
        associatedContacts: boolean;
        accountAdminUser: boolean;
        accountAdminCreateUser: boolean;
        auth: {
            login: boolean;
        };
        brandFilter: {
            brand: boolean;
            content: boolean;
        };
        brandSelection: boolean;
        chatbot: boolean;
        connectedPortal: {
            internal: boolean,
            distributors: boolean,
            distributorStats: boolean,
            dealers: boolean,
            systemType?: boolean,
            warrantyLookup?: boolean,
            ecobeeDiagnosticsTestingFeature?: boolean,
            ecobeeSystemsFeature?: boolean,
            ecobeeEditConfigFeature?: boolean,
            dealerSettingFeature?: boolean,
            addControlFeature?: boolean,
            ecobeeRuntimeReport?:boolean,
            opportunityReportUsageGraph?: boolean,
            addControlDisableNotification? :boolean,
            dealerOpportunities?: boolean,
            fad?: boolean
            ecobeeNiRuntimeLink?: boolean,
            linkedDealersEnabled?: boolean,
            factorySystemsOverviewEnabled?: boolean,
            vppEligiblityReportEnabled?: boolean,
            energyEventsEnabled?: boolean;
            fleetAnalysisFeature?: boolean;
        };
        dealerSearchCriteriaCIN: boolean;
        product: {
            worksWellWith: boolean;
            options: boolean;
        };
        profile: boolean;
        relationshipType: boolean;
        searchContent: boolean;
        searchProgram: boolean;
        selectPrimaryDistributor: boolean;
        socialLogin: boolean;
        suggestions: boolean;
        tradePartner: boolean;
        userValidation: boolean;
        partsCatalog: boolean;
        googleAddressValidation: boolean;
        pendo: boolean;
        registrationRequest: boolean;
    };
    iframePath: string;
    gatewayPath: string;
    gatewayPathNoProtocol: string;
    idmPath: string;
    mocks?: Mocks,
    secureBaseUrl: string;
    siteUrls: {
        default: string;
        carrier: string;
        bryant: string;
        arcoaire: string;
        airquest: string;
        comfortmaker: string;
        dayandnight: string;
        heil: string;
        keeprite: string;
        tempstar: string;
        totaline: string;
    };
    socialLogin?: {
        idpDisplay: string;
    };
    trackingId?: string;
}

export interface PrivateEnvironment {
    envName: EnvName;
    addressDoctor: {
        baseUrl: string;
        api: string;
        cred?: {
            login: string;
            password: string;
        };
        ProcessMode: 'ADDRESSCODELOOKUP' | 'BATCH' | 'CERTIFIED' | 'FASTCOMPLETION' | 'INTERACTIVE',
        MaxResultCount: number,
        GlobalPreferredDescriptor: 'SHORT' | 'LONG' | 'PRESERVE_INPUT'
        RangesToExpand: 'All' | 'ONLY_WITH_VALID_ITEMS'
    };
    production: boolean;
    brand: string;
    appId: string;
    appziSrc?: string;
    secureBaseUrl: string;
    logoutUrl: string;
    loginErrorUrl: string;
    logoutFrameUrl: string;
    secureCallbackUrl?: string;
    chatbotUrl?: string;
    primerFrameUrl?: string;
    connectedPortalKey: string;
    api: {
        acceleratedSignUp: string;
        accountStatusApi: string;
        accountUserPrefApi: string;
        accountUserApi: string;
        deactivateUserApi: string;
        reactivateUserApi: string;
        syncUserToServicesApi: string;
        catalog: string;
        content: string;
        publicContent: string;
        accountAdminDistributorBranchApi: string;
        accountAdminLegalEntityApi: string;
        accountAdminRelationshipTypes: string;
        accountAdminAssociatedBrands: string;
        accountAdminCompanyTypes: string;
        accountAdminBrands: string;
        accountAdminCompanyById: string;
        brandRelationshipValidation: string;
        accountAdminUpdateCompanyDetails: string;
        accountAdminParentCompanyById: string;
        accountAdminDistributorAssociations: string;
        accountAdminAssociationExportApi: string;
        accountAdminCompanyServices: string;
        accountAdminCompanySearch: string;
        accountAdminCompanyAuthSearch: string;
        accountAdminContacts: string;
        accountAdminContactSearch: string;
        accountAdminUserSearch: string;
        accountAdminCountries: string;
        accountAdminRelationships: string;
        accountAdminContactTypes: string;
        accountAdminContactById: string;
        accountAdminContactsById: string;
        accountAdminCompanyCounties: string;
        accountAdminContactsByCompany: string;
        accountAdminUsersByCompany: string;
        accountAdminCompanyRelationshipsById: string;
        accountAdminCompanyDeactivateById: string;
        accountAdminCompanyReactivateById: string;
        accountAdminUserById: string;
        accountAdminUserRegistrationDetails: string;
        accountAdminUserCreateRegisterDetails: string;
        accountAdminUserRelationshipsDetails: string;
        accountAdminUserRegistrationRequestDetails: string;
        accountAdminUserAuthorizedRoles: string;
        accountAdminUserAuthorizedFeatures: string;
        accountAssociatedDistributor: string;
        accountAdminManagers: string;
        accountAdminExport: string;
        accountAdminUserExport: string;
        accountAdminProgramSearch: string;
        accountAdminProgramBrands: string;
        accountAdminProgram: string;
        accountAdminProgramEligibilityCriteria: string;
        accountAdminProgramCriteria: string;
        accountAdminProgramEligibleCompanies: string;
        accountAdminProgramLocatorIcons: string;
        accountAdminProgramEnrollments: string;
        accountAdminProgramEnrollmentDetails: string;
        accountAdminProgramEnrollmentStatus: string;
        accountAdminProgramEnrollmentLevel: string;
        accountAdminProgramEnrollmentExport: string;
        accountAdminCompanyDealerLocatorBrands: string;
        companies: string;
        companyId: string;
        accountAdminCompany: string;
        connectedPortal: {
            graphql: string;
            'ui/config': string;
            webSocketUrl: string;
            webSocketKey: string;
            homeOwnerResetAPI: string;
        }
        lastLoginApi: string;
        legacyLogin?: string;
        search: string;
        suggestions: string;
        accountApprovalRequestsApi: string;
        accountCompanyApi: string;
        accountCreateApi: string;
        accountEmailCodeApi: string;
        accountEmailVerificationApi: string;
        accountEmailApi: string;
        accountQuicklinksPersonalizationApi: string;
        accountNotificationRemoveApi: string;
        bookmarks: string;
        document: string;
        documentPublic: string;
        documentPublicParts: string;
        inbox: string;
        inboxDetails: string;
        inboxDownload: string;
        resetPasswordApi: string;
        brandCodeApi: string;
        distributorLocator?: string;
        siteMap: string;
        user: string;
        coolCalcTerms: string;
        dealerLocatorEnrollments: string;
        dealerProductOfferings: string;
        dealerLocatorEnrollmentsPost: string;
        dealerLocatorThirdPartyRating: string;
        exportCounties: string;
        validateSessionUser: string;
        totalineContent: string;
        coolCalc: {
            sessionUserURL: string,
            APIClientEndpoint: string,
            APIDomain: string;
            MJ8AjaxURL: string,
            MJ8ReportURL: string,
            SVGIconURL: string,
            emailURL: string,
            khipuConfigURL: string,
            mapTraceConfigURL: string
        }
        coolCalcSupportRequest: string;
        getCountries: string;
        getStateListByCountry: string;
        pim: {
            catalog: string;
            suggestions: string;
            partDetail: string;
            search: string;
        }
        profileDetails: string;
        subscription: string;
        tradePartners: {
            openPos: string;
            vendorASN: string;
            pOacknowledgement: string;
        }
        warrantyLookup: string;
        validateSoldTo: string;
    };
    auth: {
        createAccountUrl: string;
        createAccountRegisterUrl: string;
        baseUrl: string;
        clientId: string;
        redirectUri: string;
        postLogoutRedirectUri: string;
        identityProviders?: {
            type: string;
            id: string;
        }[]
    };
    frameContent?: {
        baseUrl: string;
        searchUrl?: string;
        maxLoaderTime: number;
        inboxUrl?: string;
    };
    gatewayPath: string;
    gatewayPathNoProtocol: string;
    googleMapAPI: string;
    siteUrls: {
        default: string;
        carrier: string;
        bryant: string;
        arcoaire: string;
        airquest: string;
        comfortmaker: string;
        dayandnight: string;
        heil: string;
        keeprite: string;
        tempstar: string;
        totaline: string;
    };
    socialLogin?: {
        idpDisplay: string;
    };
    features: {
        accountAdmin?: boolean;
        accountAdminDealers?: boolean;
        associatedContacts?: boolean;
        companyCreate?: boolean;
        auth: {
            login: boolean;
        };
        brandFilter: {
            brand: boolean;
            content: boolean;
        };
        chatbot: boolean;
        connectedPortal: {
            internal: boolean;
            distributors: boolean;
            dealers: boolean;
        };
        covid19Information?: boolean;
        dealerSearchCriteria?: boolean;
        dealerSearchCriteriaCIN?: boolean;
        newsLetterSignUp?: boolean;
        product: {
            worksWellWith: boolean;
            options: boolean;
        };
        profile?: boolean;
        relationshipType?: boolean;
        searchContent: boolean;
        selectPrimaryDistributor?: boolean;
        signInTeaser?: boolean;
        suggestions: boolean;
        userValidation?: boolean;
        partsCatalog: boolean;
        googleAddressValidation: boolean;
    };
    documents?: {
        whatsNewAge?: number;
        bulletinAge?: number;
    };
    mocks?: Mocks
    googleAddressValidationApi: string;
    trackingId?: string;
}
